// views
$(document).on("turbolinks:load", function (e) {
  // this seems to clear the content for the upcoming class part
  $('.col-lg-4.col-md-6.col-sm-6')

  var date = new Date();
  $('#class_filter').daterangepicker({
    'autoApply': true,
    // 'startDate': moment(date),
    // 'endDate':  moment(date).endOf('month'),
    opens: 'left',
  }, function (start, end, label) {
    console.log("A new date selection was made: " + start.format('MM-DD-YYYY') + ' to ' + end.format('MM-DD-YYYY'));
  });

  // this AJAX req is for setting the list of classes 
  $.ajax({
    url: `/classes`,
    type: "GET",
    dataType: "json",
    success: function (data) {
      console.log(data.upcoming_class);
      $('#display_upcoming').empty();
      $.each(data.upcoming_class, function (key, value) {
        days = moment(value.lecture_date_time).calendar(null, {
          sameDay: '[Today]',
          nextDay: '[Tomorrow]',
          nextWeek: '[Next] dddd',
          lastDay: '[Yesterday]',
          lastWeek: '[Last] dddd',
          sameElse: 'dddd'
        })

        to_append = '<a href="/students/'+value.student_id+'" class="btn btn-primary ml-auto">Go to Student</a>'

        if (value.zoom_link_present) {
          to_append = '<a href="'+value.zoom_link+'" target="_blank" class="btn btn-success ml-auto">Attend Class</a>'
        }
        // $('#display_upcoming').empty();
        $('#display_upcoming').append(`
          <div class="col-lg-4 col-md-6 col-sm-6">
            <div class="card mb-3">
              <div class="row no-gutters h-100" style="min-height: 200px">
                <div class="col-md-5 d-flex justify-content-center align-items-center" style="background-color: #535353; background-image: url(${value.lecture_image}); background-size: cover;">
                  <div class="my-3">
                    <h4 class="text-white text-center card-text" style="background-color: rgba(50.2, 50.2, 50.2, .5);>${moment(value.lecture_date_time).format('MMMM DD')}</h4>
                    <p class="text-white text-center card-text">
                      ${days} <br>
                      ${moment.utc(value.lecture_date_time).format('h:mm A')} - ${moment.utc(value.end_time).format('h:mm A')}
                    </p>
                  </div>
                </div>
                <div class="col-md-7 d-flex justify-content-center align-items-center">
                  <div class="card-body">
                    <h5 class="card-title">${value.lecture_name}</h5>
                    <p class="card-text">
                      Teacher ${value.teacher_name} <br>
                      Student: ${value.student_name}
                    </p>
                    ${to_append}
                  </div>
                </div>
              </div>
            </div>
          </div>     
          `);
      })
    }
  });
});

//show
function display_enroll(data) {
  $('#enroll_information').html(`
  <div class="list-group list-group-flush mb-4">
    <div class="list-group-item bg-transparent d-flex align-items-center px-0">
      <strong>Class</strong>
      <div class="ml-auto">${data.lecture_name}</div>
    </div>
    <div class="list-group-item bg-transparent d-flex align-items-center px-0">
      <strong>Time</strong>
      <div class="ml-auto text-right">
        ${moment(data.date_time).format("LL - dddd")} <br>
        ${moment.utc(data.lecture_date_time).format('h:mm A')} - ${moment.utc(data.end_time).format('h:mm A')}
      </div>
    </div>
  
    <div class="list-group-item bg-transparent d-flex align-items-center px-0">
      <strong>Student</strong>
      <div class="ml-auto">
      </div>
    </div>
  </div>
  
  <div class="card card-body mb-3 bg-white">
    <b>Guidelines</b>
    <ul class="list-unstyled text-muted ml-1 mb-0 mt-1">
    </ul>
  </div>
  
  <div class="d-flex align-items-center mb-3 mt-3">
    <div class="ml-auto h2 mb-0"><strong>PHP ${data.id}</strong></div>
  </div>
  
  <div class="mb-2">
    <button class="btn btn-success btn-block btn-lg" id="enroll-btn">Enroll</button>
    <div class="text-muted p-3">You will be taken out of MindShare App to complete your payment.</div>
  </div>`);
  $('#enroll_class').addClass("show");
}
$('body').on("click", "#enroll_btn", function () {
  console.log("clicked!");
  enroll_id = $(this).data("lecture-id");
  console.log(enroll_id);
  classes_id = $("#courses_id").val();
  console.log(classes_id);

  $.ajax({
    url: classes_id + "/enroll-modal/" + enroll_id,
    type: "GET",
    dataType: "JSON",
    success: function (data) {
      $data = data;
      console.log($data);
      display_enroll(data);
    },
    error: function (data) {
      console.error(data);
    }
  });

  // show.html.erb

});